.page-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-p1-3);
}

.dashboard-header {
  padding: 28px 32px 24px;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-between;
}

.dashboard-header > h1 {
  width: 80%;
}

.center-flex-row {
  display: flex;
  align-items: center
}

.dashboard-drift-handle {
  margin-left: 15px;
}

.page-dashboard--header {
  border-bottom: 1px solid var(--color-p1-4);
}

.highcharts-legend-item text {
  font-family: var(--main-font-family--semibold) !important;
}

.tide-footer.marketable-moment--body-carousel-footer .invite-user-container {
  padding: 0;
}

.tide-footer.marketable-moment--body-carousel-footer .invite-user-container .invite-user-field-input .invite-user-field-input-info {
  bottom: 17px;
}

.tide-footer.marketable-moment--body-carousel-footer .invite-user-list-container:not(:first-of-type) span.tide-select--label {
  display: inline-block;
  color: inherit;
}

.tide-footer.marketable-moment--body-carousel-footer .invite-user-list-container:not(:first-of-type) .invite-user-field-select {
  margin-top: 0;
}

.tide-footer.marketable-moment--body-carousel-footer .invite-user-container .invite-user-list-cancel {
  padding: 8px 16px;
  width: 45px;
  height: 35px;
  margin-top: 14px;
}

.tide-footer.marketable-moment--body-carousel-footer .invite-user-container .tide-input--description.tide-paragraph-1 {
  margin: 0;
}

/* shims */
.invite-users-modal.tide-base-modal-content {
  top: 10%;
}

.invite-users-modal-button {
  display: flex;
}

.invite-user-list-cancel,
.invite-user-list-cancel.tide-button {
  border: none;
  padding: 0px;
  margin-left: 4px;
  margin-top: 8px;
  width: 30px !important;
}

.invite-user-list-add.tide-button {
  color: var(--main-navy-dark) !important;
}

.invite-user-list-add.tide-button:hover {
  color: var(--main-navy-extra-dark) !important;
}

.invite-users-modal-button .tide-base-modal-close {
  position: absolute;
  right: 0;
}
